export default {};
const width = window.innerWidth,
  height = window.innerHeight;

const pixelRatio = window.devicePixelRatio;
const normalize = (size: number) => {
  // console.log("pixel", pixelRatio);
  // console.log("size", size);
  if (pixelRatio >= 2) {
    // console.log(">2", Math.round(size * pixelRatio));
    return Math.round(size * pixelRatio) - 3;
  }
  // console.log("result", Math.round(size * pixelRatio));

  return Math.round(size * pixelRatio);
};

export { normalize };

import {Colors, FontSize, Fonts} from '../../Constants';
import styled, {css} from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Text = styled(Typography)<any>`
  color: ${props => Colors[props.theme].primary.light};
  font-family:${Fonts.primaryMedium};
  ${FontSize.sz14};
  line-height: 1.6 !important;
  letter-spacing: 0 !important;
  
  ${props => props.noMargin && css`
  margin: 0 !important;
  `}
  ${props => props.textNoWrap && css`
  white-space: nowrap !important;
  `}
  ${props => props.textCenter && css`
    text-align: center !important;
  `}
  ${props => props.textRight && css`
    text-align: right !important;
  `}
  ${props => props.bold && css`
    font-weight: 600 !important;
  `}

  ${props => props.fontFamily === "bold" && css`
    font-family:${Fonts.primaryBold};
  `}
  ${props => props.fontFamily === "book" && css`
    font-family:${Fonts.primaryBook};
  `}
  ${props => props.fontFamily === "secondary" && css`
    font-family:${Fonts.secondary};
  `}

  ${props => props.size === "9" && css`
    ${FontSize.sz9};
  `}
  ${props => props.size === "10" && css`
    ${FontSize.sz10};
  `}
  ${props => props.size === "11" && css`
    ${FontSize.sz11};
  `}
  ${props => props.size === "12" && css`
    ${FontSize.sz12};
  `}
  ${props => props.size === "14" && css`
    ${FontSize.sz14};
  `}
  ${props => props.size === "16" && css`
    ${FontSize.sz16};
  `}
  ${props => props.size === "18" && css`
    ${FontSize.sz18};
  `}
  ${props => props.size === "20" && css`
    ${FontSize.sz20};
  `}
  ${props => props.size === "22" && css`
    ${FontSize.sz22};
  `}
  ${props => props.size === "24" && css`
    ${FontSize.sz24};
  `}
  ${props => props.size === "26" && css`
    ${FontSize.sz26};
  `}
  ${props => props.size === "28" && css`
    ${FontSize.sz28};
  `}

  ${props => props.color === "primaryDefault" && css`
    color: ${props => Colors[props.theme].primary.default};
  `}
  ${props => props.color === "primaryMain" && css`
    color: ${props => Colors[props.theme].primary.main};
  `}
  ${props => props.color === "primaryLight" && css`
    color: ${props => Colors[props.theme].primary.light};
  `}
  ${props => props.color === "secondary" && css`
    color: ${props => Colors[props.theme].secondary.light};
  `}
  ${props => props.color === "error" && css`
    color: ${props => Colors[props.theme].error.light};
  `}
  ${props => props.color === "warning" && css`
    color: ${props => Colors[props.theme].warning.light};
  `}
  ${props => props.color === "success" && css`
    color: ${props => Colors[props.theme].success.light};
  `}
  ${props => props.color === "info" && css`
    color: ${props => Colors[props.theme].info.light};
  `}
`

export {Text};

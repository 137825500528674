import React from "react";
import { useState, useContext } from "react";
import Atoms from "../../Atoms";
import { ContextBase } from "../../Hooks";
const ComponetDummy: React.FC = () => (
  <span>
    <button>up</button>
    <br></br>
    <button>right</button>
    <button>left</button>
    <button>down</button>
  </span>
);

const Componet1 = (props: any) => {
  let com: any = useContext(ContextBase);
  console.log(com.theme);
  return (
    <>
      <Atoms.Atom1 theme={com.theme} />
    </>
  );
};

export default Componet1;
export { ComponetDummy };

import Component1,{ComponetDummy} from "./Component1";
import TableInbound from './TableInbound'
import TableOutbound from './TableOutbound'
import TableStreams from './TableStreams'
import SelectTheme from './SelectTheme'
import InputPanel from './InputPanel'
import OutputPanel from './OutputPanel'
import ForwardCurve from './ForwardCurve'
import CentralPanel from './CentralPanel'
import TransferList from './TransferList'
export default { 
    Component1, 
    TableInbound,
    TableOutbound,
    TableStreams,
    ComponetDummy, 
    SelectTheme,
    InputPanel,
    OutputPanel,
    ForwardCurve,
    CentralPanel,
    TransferList
 };

import { FontSize, Colors, Fonts } from "../../Constants";
import styled from "styled-components";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AppBarStyle = styled(AppBar)`
  background-color: transparent !important;
  width: auto !important;
  display: inline-flex !important;
  box-shadow: none !important;
  min-height: 26px !important;
  span[class*="indicator"]{
    background-color: ${props => Colors[props.theme].tab.indicator} !important;
  }
`;
const TabsStyle = styled(Tabs)`
  color:  ${props => Colors[props.theme].primary.invert} ;
  min-height: 26px !important;
`;
const TabStyle = styled(Tab)`
  color: yellow ;
  min-width: auto !important;
  min-height: 26px !important;
  padding: 0 5px !important;
  ${FontSize.header};
  font-family:${Fonts.primaryBook};
`;

export {AppBarStyle, TabsStyle, TabStyle};

import { Colors } from "../../Constants";

import Styled, {css} from "styled-components";

const Card = Styled.div<any>`
  border: 1px solid;
  border-color: ${props => Colors[props.theme].border.light};
  background-color: ${props => Colors[props.theme].background.default};
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  ${props => props.fullHeight && css`
    height: 100%;
  `}
  ${props => props.whitHeader && css`
    padding: 0 !important;
    /* display: flex;
    flex-direction: column; */
  `}
`;

const CardBody = Styled.div<any>`
  padding: 8px;
  overflow: auto;
  box-sizing: border-box;
  ${props => props.tableContent && css`
    height: calc(100% - 42px);
    @media screen and (max-width:959px){
      height: 300px !important;
    }
  `}
`;

const HeaderCard = Styled.div<any>`
  border-bottom: 1px solid;
  border-color: ${props => Colors[props.theme].border.light};
  background-color: ${props => Colors[props.theme].background.main};
  border-radius: 4px 4px 0 0;
  min-height: 41px;
  padding: 4px 8px;
  /* margin: -8px -8px 10px; */
  box-sizing: border-box;
  display: flex;
  align-items:  ${props => props.alignItems ? props.alignItems : "center"};
  justify-content: ${props => props.justify ? props.justify : "flex-start"};
  flex-wrap: wrap;
`;


export {Card, HeaderCard, CardBody};


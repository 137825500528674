var Colors: any = {
  lightBlue: {
    primary: {
      default: '#00a8e8',
      main: 'rgba(0,0,0,.6)',
      light: '#000000',
      input: 'rgba(0,0,0,.6)',
      invert: '#FFFFFF'
    },
    secondary: {
      main: '#225aab',
      light: '#00a8e8',
      checkbox: '#225aab',
    },
    error: {
      main: '#d4372e',
      light: '#d4372e',
    },
    warning: {
      main: '#E46916',
      light: '#FF7A00',
    },
    info: {
      main: '#358AD2',
      light: '#5cb3fd'
    },
    success: {
      main: '#1b871e',
      light: '#24B227',
    },
    background: {
      default: '#ffffff',
      main: '#1a2a41',
      light: '#dee4e9',
      headerTable: '#1a2a41',
      input: '#ffffff',
      scroll: '#1a2a41',
      scrollFront: '#225aab',
      body: '#ffffff',
    },
    gray: {
      default: '#225aab',
      main: '#dee4e9',
      light: '#c6d0d5',
    },
    layer: {
      main: 'rgba(13,22,35,.8)'
    },
    tab: {
      indicator: '#d22c2b',
    },
    border: {
      main: '#E6E6E6',
      light: '#1a2a41',
    },
  },
  blue: {
    primary: {
      default: '#FFFFFF',
      main: 'rgba(255,255,255,.6)',
      light: '#FFFFFF',
      input: '#FFFFFF',
      invert: '#FFFFFF'
    },
    secondary: {
      main: '#2659AD',
      light: '#163DAD',
      checkbox: '#FFFFFF',
    },
    error: {
      main: '#A82322',
      light: '#d22c2b',
    },
    warning: {
      main: '#E46916',
      light: '#FF7A00',
    },
    info: {
      main: '#358AD2',
      light: '#5cb3fd'
    },
    success: {
      main: '#1b871e',
      light: '#24B227',
    },
    background: {
      default: '#1A2A41',
      main: '#213653',
      headerTable: '#213653',
      light: '#26446D',
      input: '#0D1623',
      scroll: '#0D1623',
      scrollFront: '#163DAD',
      body: '#313131',
    },
    gray: {
      default: '#2659AD',
      main: '#222222',
      light: '#213653',
    },
    layer: {
      main: 'rgba(13,22,35,.8)'
    },
    tab: {
      indicator: '#d22c2b',
    },
    border: {
      main: '#FFFFFF',
      light: '#FFFFFF',
    },
  },
  black: {
    primary: {
      default: '#e46916',
      main: 'rgba(255,255,255,.6)',
      light: '#FFFFFF',
      input: 'rgba(0,0,0,.6)',
      invert: '#FFFFFF'
    },
    secondary: {
      main: '#e46916',
      light: '#e46916',
      checkbox: '#e46916',
    },
    error: {
      main: '#d9534f',
      light: '#d9534f',
    },
    warning: {
      main: '#d8d827',
      light: '#e46916',
    },
    info: {
      main: '#5cb3fd',
      light: '#262626'
    },
    success: {
      main: '#1b871e',
      light: '#1b871e',
    },
    background: {
      default: '#0b090c',
      main: '#0d0d0d',
      headerTable: '#0d0d0d',
      light: '#313131',
      input: '#ffffff',
      scroll: '#0b090c',
      scrollFront: '#e46916',
      body: '#313131',
    },
    gray: {
      default: '#7A7A7A',
      main: '#313131',
      light: '#0d0d0d',
    },
    layer: {
      main: 'rgba(30,30,30,.8)'
    },
    tab: {
      indicator: '#e46916',
    },
    border: {
      main: '#313131',
      light: '#313131',
    },
  },
  green: {
    primary: {
      default: '#239e48',
      main: 'rgba(255,255,255,.6)',
      light: '#FFFFFF',
      input: 'rgba(0,0,0,.6)',
      invert: '#FFFFFF'
    },
    secondary: {
      main: '#1b871e',
      light: '#239e48',
      checkbox: '#1b871e',
    },
    error: {
      main: '#9e1814',
      light: '#9e1814',
    },
    warning: {
      main: '#d8d827',
      light: '#c7580d',
    },
    info: {
      main: '#5cb3fd',
      light: '#262626'
    },
    success: {
      main: '#1b871e',
      light: '#1b871e',
    },
    background: {
      default: '#0b090c',
      main: '#000',
      headerTable: '#0d0d0d',
      light: '#313131',
      input: '#ffffff',
      scroll: '#0b090c',
      scrollFront: '#239e48',
      body: '#313131',
    },
    gray: {
      default: '#7A7A7A',
      main: '#313131',
      light: '#000',
    },
    layer: {
      main: 'rgba(30,30,30,.8)'
    },
    tab: {
      indicator: '#239e48',
    },
    border: {
      main: '#313131',
      light: '#313131',
    },
  },
};

export default Colors;

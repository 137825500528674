import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ButtonIcon} from "../../Styles/Atoms/Button";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <ButtonIcon
      {...props}
      size={props.size}
      theme={com.theme}
    >
      {props.children}
    </ButtonIcon>
  );
};

import React, { useEffect, useState, useContext }from "react";
import { ContextBase } from "../../Hooks";
import Grid from '@material-ui/core/Grid';
import Atoms from "../../Atoms";
import Components from "../../Components"
import {AppBarStyle, TabsStyle, TabStyle} from "../../Styles/Atoms/Tabs";
import PropTypes from 'prop-types';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{height: "100%"}}
    > 
      {value === index && (
          <div style={{height: "100%"}}>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InputPanel = (props: any) => {
  let com: any = useContext(ContextBase);
  const tenorSelectOptions = com.tenorSelectOptions;
  const pairSelectOptions = com.pairSelectOptions;
  const streamSelectOptions = com.streamSelectOptions;
  const [value, setValue] = React.useState(0);
  const [rowDataOutbound, setRowDataOutbound] = useState([])
  const [rowDataStream, setRowDataStream] = useState([])
  const [tempPair, setTempPair] = useState(pairSelectOptions[0]);
  const [tempStreamOutbound, setTempStreamOutbound] = useState(streamSelectOptions[0]);
  const [tempStream, setTempStream] = useState(streamSelectOptions[0]);
  const [tempTier, setTempTier] = useState("");
  const [tempTenor, setTempTenor] = useState(tenorSelectOptions[0]);
  const [openOutputModal, setOpenOutputModal] = React.useState(false);
  const [openStreamModal, setOpenStreamModal] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false)

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  const validateTier=(num)=>{
    const validInput = /^$|^[0-9]+$/g;
    if (num.match(validInput) && num.length<4) {
      setTempTier(num)
      setIsEmpty(false);
    } else { return }
  } 
  
  const handleClickOpenOutputModal = () => {
    setOpenOutputModal(true);
  };

  const handleClickOpenStreamModal = () => {
    setOpenStreamModal(true);
  };

  const handleCloseOutputModal = () => {
    setTempPair(pairSelectOptions[0]);
    setTempStreamOutbound(streamSelectOptions[0]);
    setTempTier("")
    setIsEmpty(false);
    setTempTenor(tenorSelectOptions[0])
    setOpenOutputModal(false);
  };

  const handleCloseStreamModal = () => {
    setTempStream(streamSelectOptions[0]);
    setOpenStreamModal(false);
  };

  const addZeroToTime = (min) =>{
    const tempMin = Number(min)
    if (tempMin<10) {
      return "0"+tempMin
    } else {
      return min;
    }
  }

  const actionSaveRowOutbound = () => {
    const newData = [...rowDataOutbound]
    const hours = addZeroToTime(new Date().getHours());
    const minutes = addZeroToTime(new Date().getMinutes());
    
    if (tempTier!=="" && tempTenor!=="" && (tempTenor.lastIndexOf(".")+1!== tempTenor.length)){
      newData.push({
        Pair: tempPair, 
        time: hours+":"+minutes,
        Stream: tempStream, 
        tier: tempTier+"M",
        Bid: "49.0020",
        bidTier: 1,
        spread: 20, 
        Offer: 4.4920,
        offerTier: 1,
        Tenor: tempTenor
      })
      com.dispatch({type:"saveOutboundData", payload:newData})
        setTempPair(pairSelectOptions[0]);
        setTempStreamOutbound(streamSelectOptions[0]);
        setTempTier("")
        setTempTenor(tenorSelectOptions[0])
        setIsEmpty(false);
      handleCloseOutputModal();
    } else {
      setIsEmpty(true);
    }
    return null;
  };

  const actionSaveRowStream = () => {
    const newData = [...rowDataStream]

    newData.push({
      Stream: tempStream,
      Status: "STOPPED",
      })
    com.dispatch({type:"saveStreamData", payload:newData})
      setTempStream(streamSelectOptions[0]);
    handleCloseStreamModal();
    return null;
  };

  useEffect(()=>{
    setRowDataOutbound(com.outboundData)
  },[com.outboundData])

  useEffect(()=>{
    setRowDataStream(com.streamData)
  },[com.streamData])

  return (
    
    <Grid item xs={12} md={4}>
    <Atoms.Card fullHeight whitHeader>

      <Atoms.HeaderCard justify="space-between">

        <AppBarStyle position="static" theme={com.theme}>
          <TabsStyle value={value} onChange={handleChange} aria-label="simple tabs example" theme={com.theme}>
            <TabStyle label="Outbound rates" {...a11yProps(0)} theme={com.theme} />
            <TabStyle label="Streams" {...a11yProps(1)} theme={com.theme} />
          </TabsStyle>
        </AppBarStyle>

        {value===0 && <Atoms.Button textColorHeader size="small" noBorder startIcon={<AddCircleOutline/>} className="hiddenText" onClick={handleClickOpenOutputModal}><span className="hiddenText">Add</span></Atoms.Button>}
        {value===1 && <Atoms.Button textColorHeader size="small" noBorder startIcon={<AddCircleOutline/>} className="hiddenText" onClick={handleClickOpenStreamModal}><span className="hiddenText">Add</span></Atoms.Button>}
        
        {/* BEGIN code for OuputModal */}
        <Atoms.Dialog
          open={openOutputModal}
          onClose={handleCloseOutputModal}
          onSave={actionSaveRowOutbound}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          titleText="Add Outbound"
          newItem
        >
          
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Atoms.Select 
                name="select" 
                label="CCY.PAIR:" 
                column onChange={(e)=>setTempPair(e.target.value)}>
                {pairSelectOptions.map((item, index)=>{
                  return <Atoms.Option key={"pairSelectOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Atoms.Select 
                name="select" 
                label="STREAM:" 
                column
                onChange={(e)=>setTempStreamOutbound(e.target.value)} 
              >
                {streamSelectOptions.map((item, index)=>{
                  return <Atoms.Option key={"streamSelectOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Atoms.Input 
                type="text" 
                name="tier" 
                label="TIER:"
                error={isEmpty}
                column 
                value={tempTier}
                onChange={(e)=>validateTier(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Atoms.Select 
                name="tenorSelect" 
                label="TENOR:" 
                column
                onChange={(e)=>setTempTenor(e.target.value)} 
              >
                {tenorSelectOptions.map((item, index)=>{
                  return <Atoms.Option key={"tenorSelectOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
          </Grid>
        </Atoms.Dialog>
        {/* END code for OuputModal */}
        {/* BEGIN code for StreamModal */}
        <Atoms.Dialog
          open={openStreamModal}
          onClose={handleCloseStreamModal}
          onSave={actionSaveRowStream}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          titleText="Add STREAM"
          newItem
        >
          
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Atoms.Select 
                name="select" 
                label="STREAM:" 
                column
                onChange={(e)=>setTempStream(e.target.value)}
              >
              {streamSelectOptions.map((item, index)=>{
                return <Atoms.Option key={"streamSelectOption-"+index} value={item}>{item}</Atoms.Option >
              })}
              </Atoms.Select>
            </Grid>
          </Grid>
        </Atoms.Dialog>
        {/* END code for StreamModal */}

      </Atoms.HeaderCard>
      <Atoms.CardBody tableContent>
        <TabPanel value={value} index={0}>
          <Components.TableOutbound rowData={rowDataOutbound}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Components.TableStreams rowData={rowDataStream}/>
        </TabPanel>
      </Atoms.CardBody>

    </Atoms.Card>
  </Grid>
  );
};

export default InputPanel;

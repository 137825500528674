import styled from 'styled-components';

const Wrapper = styled.div<any>`
  height: 100%;
  .cursor{
    cursor: pointer;
  }
  .contentA{
    height: ${props => props.onlyOne ? "100%" : "29px"};
    display: flex;
    align-items: ${props => props.onlyOne ? "center" : " flex-end"};
    padding-bottom: ${props => props.onlyOne ? "0" : "5px"};
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    /* background-color: red; */
    span{
      line-height: .86 !important;
    } 
    &.column{
      flex-direction: column;
    }
    .absolute{
      position: absolute;
      bottom: 5px;
    }
  }
  .contentB{
    box-sizing: border-box;
    height: 28px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* background-color: orange; */
    &.paddingTop{
      padding-top: 0px;
    }
  }
`

export {Wrapper};

import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {AgGridReactStyle} from "../../Styles/Atoms/AgGrid"

import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../Styles/Components/TableAg/ag-theme.css";

import Pair from "../../Atoms/TableAtoms/Pair";
import Stream from "../../Atoms/TableAtoms/Stream";
import Price from "../../Atoms/TableAtoms/Price";
import PriceOffer from "../../Atoms/TableAtoms/PriceOffer";
import Spread from "../../Atoms/TableAtoms/Spread";
import BtnGroup from "../../Atoms/TableAtoms/BtnGroup";

const Table = (props: any) => {
  let com: any = useContext(ContextBase);
  

  const [rowData, setRowData] = useState([]);
  const [hideBtns, setHideBtns] = useState(false);

  const columnDefs = [
    // {
    //   field: '',
    //   minWidth: 25,
    //   maxWidth: 25,
    //   rowDrag: true,
    //   lockPosition: true,
    // },
    {
      field: 'Pair',
      cellRenderer: "pairCellRenderer",
      minWidth: 85,
      lockPosition: true,
      rowDrag: true,
    },
    {
      field: "Stream",
      cellRenderer: "streamCellRenderer",
      minWidth: 70,
      maxWidth: 70,
      lockPosition: true,
    },
    {
      field: "Bid",
      cellRenderer: "bidCellRenderer",
      cellRendererParams: {
        inbound: false,
      },
      minWidth: 98,
      lockPosition: true,
    },
    {
      field: "Spread",
      cellRenderer: "spreadCellRenderer",
      cellRendererParams: {
        inbound: false,
      },
      minWidth: 45,
      maxWidth: 45,
      lockPosition: true,
    },
    {
      field: "Offer",
      cellRenderer: "offerCellRenderer",
      cellRendererParams: {
        inbound: false,
      },
      minWidth: 98,
      lockPosition: true,
    },
    {
      headerName: '',
      field: "btn",
      cellRenderer: "btnCellRenderer",
      minWidth: 55,
      lockPosition: true,
      hide: hideBtns
    },
  ]
  const defaultColDef = {
      editable: false,
      sortable: false,
      flex: 1,
      // minWidth: 100,
      filter: false,
      resizable: true,
      // autoHeight: true,
      rowHeight: 150,
    }

  const frameworkComponents = {
    btnCellRenderer: BtnGroup,
    pairCellRenderer: Pair,
    streamCellRenderer: Stream,
    bidCellRenderer: Price,
    spreadCellRenderer: Spread,
    offerCellRenderer: PriceOffer,
  }
  /* const [rowData, setRowData] = useState([
    { drag: "drag", Pair:"EUR/PLZ", Stream: "L.POOL1", Bid: 4.4900, spread: 20, Offer: 4.4920, x:"1" },
    { drag: "drag", Pair:"EUR/PLZ", Stream: "L.POOL1", Bid: 4.4800, spread: 20, Offer: 4.4820, x:"2" },
  ]); */

  const onColumnResized = (params:any) => {
    params.api.resetRowHeights();
  };

  const onColumnVisible = (params:any) => {
    params.api.resetRowHeights();
  };

  const showHideBtn = () => {
    if (window.innerWidth<=959) {
      setHideBtns(true);
    } else {
      setHideBtns(false);
    }
  }
  const resizeEvent = window.addEventListener('resize', showHideBtn);
  useEffect(()=>{
    setRowData(props.rowData);
  },[props.rowData])

  useEffect(()=>{
    showHideBtn();
  },[])

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      {/* <ComponetDummy /> */}
      <AgGridReactStyle
        key={"outbound"+com.theme}
        theme={com.theme}
        rowData={rowData}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        defaultColDef={defaultColDef}
        rowDragManaged={true}
        animateRows={true}
        rowHeight={60}
        onColumnResized={onColumnResized}
        onColumnVisible={onColumnVisible}
      >
        {/* <AgGridColumnStyle field="make"></AgGridColumnStyle>
        <AgGridColumnStyle field="model"></AgGridColumnStyle>
        <AgGridColumnStyle field="price"></AgGridColumnStyle> */}
      </AgGridReactStyle>
    </div>
  );
};

export default Table;

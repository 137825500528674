import { Colors, FontSize, Fonts } from "../../Constants";

import Styled, {css} from "styled-components";

const ContentInput = Styled.div<any>`
  display: flex;
  flex-direction: ${props => props.column ? "column" : "row"};
  align-items: ${props => props.column ? "flex-start" : "center"};
  color: ${props => Colors[props.theme].primary.light};
  ${FontSize.sz14};
`;
const Input = Styled.input<any>`
  border: 1px solid;
  cursor: pointer;
  border-color:  ${props => Colors[props.theme].gray.default};
  background-color:  ${props => Colors[props.theme].background.input};
  color: ${props => Colors[props.theme].primary.input };
  height: 30px;
  padding: 0 5px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  ${FontSize.sz14};
  text-transform: uppercase;
  font-family: ${Fonts.primaryBook};
  &:focus{
    outline: none !important;
  }
  &[type="time"]::-webkit-calendar-picker-indicator{
    filter: invert(50%)  hue-rotate(180deg) ;
  }
  ${props => props.error && css`
    border: 2px solid;
    border-color: ${props => Colors[props.theme].error.main};
  `}
`;


export {ContentInput, Input};
import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../../Hooks";
import {Wrapper} from "../../../Styles/Atoms/TableAtoms"
import Text from "../../Text"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <Wrapper {...props} theme={com.theme} onlyOne>
      <div className="contentA" >
        <Text variant="caption" display="block" size="15" noMargin fontFamily="bold" textCenter >{props.data.Status}</Text>
      </div>
    </Wrapper>
  );
};

import Styled from "styled-components";
import { Colors, FontSize, Fonts } from "../../Constants";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogStyle = Styled(Dialog)<any>`
  background-color: ${props => Colors[props.theme].layer.main} !important;
  .MuiDialog-paper{
    background: ${props => Colors[props.theme].background.default} !important;
    border: 1px solid;
    border-color: ${props => Colors[props.theme].border.light} !important;
  }
  .pushRight{
    position: absolute;
    right: 5px;
    top: 5px;
    color: ${props => Colors[props.theme].primary.light} !important;
  }
`;
const DialogActionsStyle = Styled(DialogActions)<any>`

`;
const DialogContentStyle = Styled(DialogContent)<any>`
  color: ${props => Colors[props.theme].primary.light} !important;
`;
const DialogContentTextStyle = Styled(DialogContentText)<any>`
  background-color: ${props => Colors[props.theme].warning.light} !important;
`;
const DialogTitleStyle = Styled(DialogTitle)<any>`
  color: ${props => Colors[props.theme].primary.light} !important;
  text-align: center;
  min-width: 200px;
  h2 {
    font-family:${Fonts.primaryBook};
    ${FontSize.sz24};
  }
`;

export {DialogStyle, DialogActionsStyle, DialogContentStyle, DialogContentTextStyle, DialogTitleStyle};
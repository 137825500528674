import Styled, {css, keyframes} from "styled-components";
import { Colors, FontSize, Fonts } from "../../Constants";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

const bouncing = keyframes`
  from {
    transform: translate3d(0, 2px, 0) scale(1.2, 0.85);
  }
  to {
    transform: translate3d(0, -2px, 0) scale(0.9, 1.1);
  }
`;


const Btn = Styled(Button)<any>`
  /* padding: 10px !important; */
  border-radius: 4px !important;
  box-shadow: none !important;
  color: ${props => Colors[props.theme].primary.light} !important;
  border: 1px solid !important;
  border-color: #7a7a7a !important;
  ${FontSize.sz9};
  font-family:${Fonts.primaryMedium};
  line-height: 1.6 !important;

  ${props => props.reduceIcon && css`
    padding: 0 !important;
    min-width: 0 !important;
    border: 0 !important;
  `}
  ${props => props.fullWidth && css`
    width: 100% !important;
  `}
  ${props => props.movilFullWidth && css`
    @media screen and (max-width:599px){
      width: 100% !important;
    }
  `}
  
  ${props => props.noBorder && css`
      border-color: transparent !important;
      color: ${props => Colors[props.theme].primary.main} !important;
  `}
  ${props => props.color === "secondary" && css`
      background-color: ${props => Colors[props.theme].secondary.main} !important;
      border-color: ${props => Colors[props.theme].secondary.main} !important;
    color: ${props => Colors[props.theme].primary.invert} !important;
  `}
  ${props => props.color === "success" && css`
      background-color: ${props => Colors[props.theme].success.light} !important;
      border-color: ${props => Colors[props.theme].success.light} !important;
    color: ${props => Colors[props.theme].primary.invert} !important;
  `}
  ${props => props.color === "error" && css`
      background-color: ${props => Colors[props.theme].error.light} !important;
      border-color: ${props => Colors[props.theme].error.light} !important;
    color: ${props => Colors[props.theme].primary.invert} !important;
  `}
  ${props => props.color === "warning" && css`
      background-color: ${props => Colors[props.theme].warning.light} !important;
      border-color: ${props => Colors[props.theme].warning.light} !important;
    color: ${props => Colors[props.theme].primary.invert} !important;
  `}
  
  ${props => props.textColorHeader && css`
    color: ${props => Colors[props.theme].primary.invert} !important;
  `}
  
  &.hiddenText{
    @media screen and (max-width:1099px){
      min-width: 40px !important;
      [class*="startIcon"]{
        margin: 0;
      }
      span[class*="hiddenText"]{
        display: none;
      }
    }
    @media screen and (max-width:959px){
      [class*="startIcon"]{
        margin-left: -2px;
        margin-right: 4px;
      }
      span[class*="hiddenText"]{
        display: inline-block;
      }
    }
  }
  .LoaderBalls {
    width: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3px;
  }
  .LoaderBalls__item {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color:  ${props => Colors[props.theme].primary.invert};
  }
  .LoaderBalls__item:nth-child(1) {
    animation: ${bouncing} 0.4s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);
  }
  .LoaderBalls__item:nth-child(2) {
    animation: ${bouncing} 0.4s 0.1s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
  }
  .LoaderBalls__item:nth-child(3) {
    animation: ${bouncing} 0.4s 0.2s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
  }
`;
const ToggleButtonGorupStyle = Styled(ToggleButtonGroup)<any>`
  & button:first-child{
    &.Mui-selected{
      background-color: ${props => (props.theme === "black") ? Colors[props.theme].success.main : Colors[props.theme].secondary.main}!important;
    }
  }
`;
const ToggleButtonStyle = Styled(ToggleButton)<any>`
  box-shadow: none !important;
  color: ${props => Colors[props.theme].primary.light} !important;
  border: 1px solid !important;
  border-color: #7a7a7a !important;
  font-family:${Fonts.primaryMedium};
  ${FontSize.header}
  
  &.MuiToggleButton-sizeSmall{
    padding: 4px 7px!important;
  }
  &.Mui-selected{
    background-color: ${props => Colors[props.theme].warning.light}!important;
  }
  
  ${props => props.textColorHeader && css`
    color: ${props => Colors[props.theme].primary.invert} !important;
  `}
`;
const ButtonIcon = Styled(IconButton)<any>`
  &.btnTable {
    height: 24px !important;
    width: 24px !important;
    color: ${props => Colors[props.theme].background.default} !important;
    padding: 0 !important;
  }
  ${props => props.color === "error" && css`
      background-color: ${props => Colors[props.theme].error.light} !important;
      border-color: ${props => Colors[props.theme].error.light} !important;
  `}
  ${props => props.color === "success" && css`
      background-color: ${props => Colors[props.theme].success.light} !important;
      border-color: ${props => Colors[props.theme].success.light} !important;
  `}
`;

export {Btn, ToggleButtonGorupStyle, ToggleButtonStyle, ButtonIcon};
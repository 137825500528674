import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ContentSelect, Select} from "../../Styles/Atoms/Select";
import Text from "../Text"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return <ContentSelect {...props} theme={com.theme}>
          <div>
            { props.column &&
              <span style={{marginLeft: "4px"}} />
            }
            <Text variant="caption" size="11" fontFamily="book" bold textCenter textNoWrap noMargin>{props.label}</Text> <span style={{marginLeft: "8px"}} />
          </div>
          <Select {...props} name={props.name} theme={com.theme}>{props.children}</Select>
        </ContentSelect>
      ;
};

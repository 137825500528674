import React, { useEffect, useState, useContext } from "react";
import Atoms from "../../Atoms";
import { ContextBase } from "../../Hooks";
import Grid from '@material-ui/core/Grid';

const SelectTheme = (props: any) => {
  let com: any = useContext(ContextBase);

  const changeTheme = (themeVal) => {
    com.dispatch({type:"changeTheme", payload: themeVal})
  }

  const [selectedTheme, setSelectedTheme] = useState("dark")

  useEffect(()=>{
    setSelectedTheme(com.theme)
  },[com])

  return (
    <>
      <Grid item xs={12} style={{height: "36px"}}>
        <Grid container item xs={12} sm={2}>
          <Atoms.Select name="select" onChange={(e)=>changeTheme(e.target.value)}>
            <Atoms.Option  value="black">Black</Atoms.Option >
            <Atoms.Option value="blue">Blue</Atoms.Option >
            <Atoms.Option value="lightBlue">Light</Atoms.Option >
            <Atoms.Option  value="green">Green</Atoms.Option >
          </Atoms.Select>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectTheme;

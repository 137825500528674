import React from "react";
import { useContext } from "react";
import { ContextBase } from "./Hooks";
import "./App.css";
import "../src/assets/fonts/stylesheet.css"
import ControlPanel from "./Modules/ControlPanel"
// import StylesAtoms from "./Styles/Atoms"

// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Provider from "./Hooks";
 
const App = () => {
  console.log(sessionStorage.getItem("AuthIframe"))
  sessionStorage.setItem('key','no')
  console.log(sessionStorage.getItem("key"))
  
  let com: any = useContext(ContextBase);
  return (
    <Provider>
      <div style={{width: "100%", height: "100%"}}>
        <ControlPanel/>
      </div>
    </Provider>
  );
};

export default App;

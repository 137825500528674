import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {DialogStyle, DialogActionsStyle, DialogContentStyle, DialogTitleStyle} from "../../Styles/Atoms/Dialog"
import Atoms from "../../Atoms"
import IconButton from '@material-ui/core/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
      <DialogStyle {...props} theme={com.theme}>
        
        <IconButton aria-label="delete" className="pushRight" size="small" onClick={props.onClose}>
          <HighlightOff fontSize="inherit"/>
        </IconButton>
        <DialogTitleStyle id="alert-dialog-title" theme={com.theme}>{props.titleText}</DialogTitleStyle>
        <DialogContentStyle theme={com.theme}>
          {/* <DialogContentText id="alert-dialog-description"> */}
            {props.children}
          {/* </DialogContentText> */}
        </DialogContentStyle>
        <DialogActionsStyle theme={com.theme}>
          { props.editItem && <Atoms.Button color="error" onClick={props.onDelete} >
            Delete
          </Atoms.Button>}
          <Atoms.Button color={props.editItem && "secondary"} onClick={props.onSave} >
            Save
          </Atoms.Button>
          <Atoms.Button onClick={props.onClose} >
            Cancel
          </Atoms.Button>
        </DialogActionsStyle>
      </DialogStyle>
    );
};

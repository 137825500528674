import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Components from "../../Components"
import Modules from "../../Styles/Modules"
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <Modules.App {...props} theme={com.theme}>
      <Grid container spacing={1} 
        style={{height:'100%'}}
      >
        <Components.SelectTheme/>
        <Hidden smDown>
          <Components.InputPanel/> 
        </Hidden>
        <Components.CentralPanel/>
        <Hidden mdUp>
          <Components.InputPanel/> 
        </Hidden>
        <Components.OutputPanel/> 
        
      </Grid>
    </Modules.App>
  );
};

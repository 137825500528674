import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../../Hooks";
import {Wrapper} from "../../../Styles/Atoms/TableAtoms"
import Text from "../../Text"
export default (props:any) => {
    let com: any = useContext(ContextBase);
    let integerValue = "";
    let largeDecimals = "";
    let remainderDecimals = "";
    if (props.value){
      const stringVal = Number(props.value).toFixed(4).toString();
      if (stringVal){
        integerValue = stringVal.lastIndexOf('.')>-1 ? stringVal.substr(0,stringVal.lastIndexOf('.')+3) : "";
        largeDecimals = stringVal.lastIndexOf('.')>-1 ? stringVal.substr(stringVal.lastIndexOf('.')+3,2) : "";
        remainderDecimals = stringVal.lastIndexOf('.')>-1 ? stringVal.substr(stringVal.lastIndexOf('.')+5,stringVal.length) : "";
      }
    }
  return (
    <Wrapper {...props} theme={com.theme} onlyOne>
        <div className="contentA column">
            <Text variant="caption" display="block" size="24" noMargin fontFamily="secondary" textCenter color="primaryMain">{integerValue}<Text variant="caption" size="28" noMargin fontFamily="secondary" color="primaryDefault">{largeDecimals}</Text>{remainderDecimals}</Text>
            { (!props.inbound && (props.data.offerTier || props.data.offerTier===0)) && <Text variant="caption" display="block" size="10" noMargin fontFamily="secondary" textCenter className="absolute"  >{props.data.offerTier>0 ? "+"+props.data.offerTier : props.data.offerTier}</Text>}
        </div>
    </Wrapper>
  );
};

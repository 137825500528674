import React, {useState} from "react";
import { useContext } from "react";
import { ContextBase } from "../../../Hooks";
import {Wrapper} from "../../../Styles/Atoms/TableAtoms"
import Text from "../../Text"
import Grid from '@material-ui/core/Grid';
import Atoms from "../../../Atoms";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  const rowDataIn = com.inboundData;
  const rowDataOut = com.outboundData;
  const pairSelectOptions = com.pairSelectOptions;
  const streamSelectOptions = com.streamSelectOptions;
  const tenorSelectOptions = com.tenorSelectOptions;
  const [tempPair, setTempPair] = useState(pairSelectOptions[0]);
  const [tempStream, setTempStream] = useState(streamSelectOptions[0]);
  const [tempTier, setTempTier] = useState("");
  const [tempTenor, setTempTenor] = useState(tenorSelectOptions[0]);
  const [openOutputModal, setOpenOutputModal] = useState(false);
  const [openInputModal, setOpenInputModal] = useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false)
  
  const validateTier=(num)=>{
    const validInput = /^$|^[0-9]+$/g;
    if (num.match(validInput) && num.length<4) {
      setTempTier(num)
      setIsEmpty(false);
    } else { return }
  }

  const handleClickOpenOutputModal = () => {
    setOpenOutputModal(true);
  };

  const handleClickOpenInputModal = () => {
    setOpenInputModal(true);
  };

  const handleCloseOutputModal = () => {
    setTempPair(pairSelectOptions[0]);
    setTempStream(streamSelectOptions[0]);
    setTempTier("")
    setTempTenor(tenorSelectOptions[0]);
    setIsEmpty(false);
    setOpenOutputModal(false);
  };

  const handleCloseInputModal = () => {
    setTempPair(pairSelectOptions[0]);
    setTempStream(streamSelectOptions[0]);
    setTempTier("")
    setIsEmpty(false);
    setOpenInputModal(false);
  };

  const addZeroToTime = (min) =>{
    const tempMin = Number(min)
    if (tempMin<10) {
      return "0"+tempMin
    } else {
      return min;
    }
  }

  const actionSaveRowInbound = (rowIndex) => {
    const newData = [...rowDataIn]
    const hours = addZeroToTime(new Date().getHours());
    const minutes = addZeroToTime(new Date().getMinutes());

    if (tempTier!==""){
      newData.splice(rowIndex, 1,{
        Pair: tempPair, 
        time: hours+":"+minutes,
        Stream: tempStream, 
        tier: tempTier+"M",
        Bid: "49.0020",
        spread: 20, 
        Offer: 4.4920,
        })
      com.dispatch({type:"saveInboundData", payload:newData})
      setTempPair(pairSelectOptions[0]);
      setTempStream(streamSelectOptions[0]);
      setTempTier("")
      setIsEmpty(false);
      handleCloseInputModal();
    } else {
      setIsEmpty(true);
    }
    return null;
  };

  const actionDeleteRowInbound = (rowIndex) => {
    const newData = [...rowDataIn]
    newData.splice(rowIndex, 1);
    com.dispatch({type:"saveInboundData", payload:newData})
  }

  const actionSaveRowOutbound = (rowIndex) => {
    const newData = [...rowDataOut]
    const hours = addZeroToTime(new Date().getHours());
    const minutes = addZeroToTime(new Date().getMinutes());

    if (tempTier!==""){
      newData.splice(rowIndex, 1,{
        Pair: tempPair, 
        time: hours+":"+minutes,
        Stream: tempStream, 
        tier: tempTier+"M",
        Bid: "49.0020",
        bidTier: 1,
        spread: 20, 
        Offer: 4.4920,
        offerTier: 1,
        Tenor: tempTenor
        })
      com.dispatch({type:"saveOutboundData", payload:newData})
        setTempPair(pairSelectOptions[0]);
        setTempStream(streamSelectOptions[0]);
        setTempTier("")
        setTempTenor(tenorSelectOptions[0]);
        setIsEmpty(false);
      handleCloseOutputModal();
    } else {
      setIsEmpty(true);
    }
    return null;
  };

  const actionDeleteRowOutbound = (rowIndex) => {
    const newData = [...rowDataOut]
    newData.splice(rowIndex, 1);
    com.dispatch({type:"saveOutboundData", payload:newData})
  }

  return (
    <Wrapper {...props} theme={com.theme}>
      <div className="contentA cursor" onClick={props.inbound ? handleClickOpenInputModal : handleClickOpenOutputModal}>
        <Text variant="caption" display="block" size="11" noMargin fontFamily="bold" textCenter >{props.value}</Text>
      </div>
      {/* BEGIN code for InputModal */}
      <Atoms.Dialog
        open={openInputModal}
        onClose={handleCloseInputModal}
        onSave={()=>actionSaveRowInbound(props.rowIndex)}
        onDelete={()=>actionDeleteRowInbound(props.rowIndex)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        titleText="Edit Inbound"
        editItem
      >
        
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Atoms.Select 
              name="select" 
              label="CCY.PAIR:" 
              column 
              onChange={(e)=>setTempPair(e.target.value)} 
              // defaultValue={"EUR/PLZ"}
            >
              {pairSelectOptions.map((item, index)=>{
                return <Atoms.Option key={"pairSelectOption-"+index} value={item}>{item}</Atoms.Option >
              })}
            </Atoms.Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <Atoms.Select 
              name="streamSelect" 
              label="STREAM:" 
              column
              onChange={(e)=>setTempStream(e.target.value)} 
            >
              {streamSelectOptions.map((item, index)=>{
                return <Atoms.Option key={"streamSelectOption-"+index} value={item}>{item}</Atoms.Option >
              })}
            </Atoms.Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <Atoms.Input 
              type="text" 
              name="tier" 
              label="TIER:" 
              error={isEmpty}
              column 
              value={tempTier}
              onChange={(e)=>validateTier(e.target.value)}
            />
          </Grid>
        </Grid>
      </Atoms.Dialog>
      {/* END code for InputModal */}
      {/* BEGIN code for OuputModal */}
      <Atoms.Dialog
        open={openOutputModal}
        onClose={handleCloseOutputModal}
        onSave={()=>actionSaveRowOutbound(props.rowIndex)}
        onDelete={()=>actionDeleteRowOutbound(props.rowIndex)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        titleText="Edit Outbound"
        editItem
      >
        
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Atoms.Select 
              name="select" 
              label="CCY.PAIR:" 
              column 
              onChange={(e)=>setTempPair(e.target.value)} 
              // defaultValue={"EUR/PLZ"}
            >
              {pairSelectOptions.map((item, index)=>{
                return <Atoms.Option key={"pairSelectOption-"+index} value={item}>{item}</Atoms.Option >
              })}
            </Atoms.Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Atoms.Select 
              name="streamSelect" 
              label="STREAM:" 
              column
              onChange={(e)=>setTempStream(e.target.value)} 
            >
              {streamSelectOptions.map((item, index)=>{
                return <Atoms.Option key={"streamSelectOption-"+index} value={item}>{item}</Atoms.Option >
              })}
            </Atoms.Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Atoms.Input  
              type="text" 
              name="tier" 
              label="TIER:" 
              error={isEmpty}
              column 
              value={tempTier}
              onChange={(e)=>validateTier(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Atoms.Select 
              name="tenorSelect" 
              label="TENOR:" 
              column
              onChange={(e)=>setTempTenor(e.target.value)} 
            >
              {tenorSelectOptions.map((item, index)=>{
                return <Atoms.Option key={"tenorSelectOption-"+index} value={item}>{item}</Atoms.Option >
              })}
            </Atoms.Select>
          </Grid>
        </Grid>
      </Atoms.Dialog>
      {/* END code for OuputModal */}
      <div className="contentB paddingTop">
        <Text variant="caption" display="block" size="9" noMargin fontFamily="book" textCenter color="primaryMain">Last: {props.data.time}</Text>
      </div>
    </Wrapper>
  );
};

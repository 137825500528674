import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Grid from "@material-ui/core/Grid";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Atoms from "../../Atoms"

export default function Parameters(props: any) {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  let com: any = useContext(ContextBase);
  const warehousingAlgoParams = com.warehousingAlgoParams;

  const structureParameters = (selectedAlgo) => {
  const selectedIndex = warehousingAlgoParams.findIndex((item)=>item.type===selectedAlgo)
    
    
    return (<React.Fragment>
      {warehousingAlgoParams[selectedIndex].params.map(item=>{
        return (
          <Grid item xs={6} sm={4}>
            <Atoms.Input name={item.label} type={item.type} label={item.label} value={item.value} column />
          </Grid>
        )
      })}
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Atoms.Button fullWidth noBorder size="small" onClick={handleClick}>
          { open ? <ExpandLess /> : <ExpandMore/> }
        </Atoms.Button>
      </Grid>
      { open && structureParameters(props.selectedAlgo)}
    </React.Fragment>
  );
}

const initialState = { 
  theme: "black",
  connectionStatus: "Closed",
  pairSelectOptions : [
    "EUR/USD",
    "EUR/PLZ",
    "EUR/MXN"
  ],
  streamSelectOptions : [
    "TOUGH FLOW",
    "L.POOL1",
    "L.POOL2"
  ],
  tenorSelectOptions : [
    "TD",
    "TOM",
    "SPOT",
    "SN",
    "2D"
  ],
  warehousingAlgoOptions: [
    "SKEW",
    "BREAK EVEN",
    "SKEW + BREAK EVEN"
  ],
  streamSetupData: [
    {
      type: "TOUGH FLOW",
      b2bItems: [
        "List Item 1",
        "List Item 2",
        "List Item 3",
        "List Item 4",
      ],
      warehousingItems: [
        "List Item 5",
        "List Item 6",
      ]
    },
    {
      type: "L.POOL1",
      b2bItems: [
        "List Item 1",
        "List Item 2",
      ],
      warehousingItems: [
        "List Item 3",
        "List Item 4",
        "List Item 5",
        "List Item 6",
      ]
    },
    {
      type: "L.POOL2",
      b2bItems: [
        "List Item 1",
        "List Item 3",
        "List Item 5",
      ],
      warehousingItems: [
        "List Item 4",
        "List Item 6",
        "List Item 7",
        "List Item 8",
      ]
    }
  ],
  warehousingAlgoParams: [
    {
      type: "SKEW",
      params: [
        {
          label: "Parameter A:",
          type: "number",
          value: 5
        },
        {
          label: "Parameter B:",
          type: "time",
          value: "10:10"
        },
        {
          label: "Parameter C:",
          type: "text",
          value: "-$5,000"
        },
        {
          label: "Parameter D:",
          type: "text",
          value: "10M"
        },
        {
          label: "Parameter E:",
          type: "text",
          value: "22/01/2021"
        },
        {
          label: "Parameter F:",
          type: "text",
          value: "LONG"
        },
      ]
    },
    {
      type: "BREAK EVEN",
      params: [
        {
          label: "Parameter A:",
          type: "number",
          value: 6
        },
        {
          label: "Parameter B:",
          type: "time",
          value: "12:22"
        },
        {
          label: "Parameter C:",
          type: "text",
          value: "-$10,000"
        },
      ]
    },
    {
      type: "SKEW + BREAK EVEN",
      params: [
        {
          label: "Parameter A:",
          type: "number",
          value: 7
        },
        {
          label: "Parameter B:",
          type: "time",
          value: "22:22"
        },
        {
          label: "Parameter C:",
          type: "text",
          value: "-$1,000"
        },
        {
          label: "Parameter D:",
          type: "text",
          value: "20M"
        },
        {
          label: "Parameter E:",
          type: "text",
          value: "28/11/2020"
        },
        {
          label: "Parameter F:",
          type: "text",
          value: "MIDDLE"
        },
        {
          label: "Parameter G:",
          type: "text",
          value: "SHORT"
        },
      ]
    }
  ],

  inboundData: [
    { 
      Pair:"EUR/PLZ", 
      time: '11:11',
      Stream: "L.POOL1", 
      tier:"1M",
      Bid: 98.491234, 
      Spread: 20, 
      Offer: 4.492000,
    },
    { 
      Pair:"EUR/USD", 
      time: '12:12',
      Stream: "L.POOL2", 
      tier:"1M",
      Bid: 5.592099, 
      Spread: 20, 
      Offer: 5.590000
    }
  ],
  outboundData: [
    {
      Pair:"EUR/PLZ",
      time: '21:21',
      Stream: "TOUGH FLOW",
      tier:"1M",
      Bid: 11.491299,
      bidTier: 0,
      Spread: 20,
      Offer: 4.492000,
      offerTier: 0
    },
    {
      Pair:"EUR/PLZ",
      time: '22:22',
      Stream: "TOUGH FLOW",
      tier:"1M",
      Bid: 4.480000,
      bidTier: 0,
      Spread: 20,
      Offer: 4.482000,
      offerTier: 0
    },
  ],
  streamData: [
    { 
      Stream:"TOUGH FLOW", 
      Status: "LIVE", 
    },
    { 
      Stream:"TOUGH FLOW", 
      Status: "LIVE", 
    },
  ]
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "changeTheme":
      return { ...state, theme: action.payload};
    case "saveConnectionStatus":
      return { ...state, connectionStatus: action.payload};
    case "saveStateUpdateFunction":
      return {...state, dispatch: action.payload};
    case "saveInboundData":
      return {...state, inboundData: action.payload};
    case "saveOutboundData":
      return {...state, outboundData: action.payload};
    case "saveStreamData":
      return {...state, streamData: action.payload};
    default:
      return state;
  }
};
export { reducer, initialState };

import React, { useContext, useEffect, useState } from "react";
import { ContextBase } from "../../Hooks";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import {BoxList} from "../../Styles/Atoms/ListTransfer"
import {Space} from "../../Styles/Atoms/Space"
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import Atoms from "../../Atoms"
 
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     width: "100%",
//     height: "100%",
//     backgroundColor: "red",
//     overflow: "auto"
//   },
//   active: {
//     backgroundColor: "gray"
//   }
// }));

function not(a:any, b:any) {
  return a.filter((value:any) => b.indexOf(value) === -1);
}

function intersection(a:any, b:any) {
  return a.filter((value:any) => b.indexOf(value) !== -1);
}

export default function TransferList(props: any) {
  let com: any = useContext(ContextBase);
  const streamSetupData = com.streamSetupData;

  const [stop, setStop] = useState(true);
  const [b2b, setB2B] = useState(false);
  const handleStop = () => {
    setStop(!stop);
  };
  const handleB2B = () => {
    setB2B(!b2b);
  };
  
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(streamSetupData[0].b2bItems);
  const [right, setRight] = useState(streamSetupData[0].warehousingItems);

 /*  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true
  }); */

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value:any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  /* const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  }; */

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  /* const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  }; */

  /* const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }; */

  const customList = (items) => (
    <BoxList theme={com.theme} >
      <List dense disablePadding component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              className={checked.indexOf(value) !== -1 && 'active'}
              role="listitem"
              button
              onClick={handleToggle(value)}
              disabled={(props.b2bStatus || props.b2bAllStatus)}
            >
              <ListItemIcon style={{ display: "none" }}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: "center" }}
                id={labelId}
                primary={`${value}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </BoxList>
  );

  const reformatData = (stream)=>{
    const selectedIndex = streamSetupData.findIndex((item)=>item.type===stream)
    if (selectedIndex>-1) {
      setLeft(streamSetupData[selectedIndex].b2bItems)
      setRight(streamSetupData[selectedIndex].warehousingItems)
    }
    return null;
  }

  useEffect(()=>{
    reformatData(props.selectedStream)
  },[props.selectedStream])

  return (
    <React.Fragment>
    <Grid item xs={12}>
      <Grid
        container
        spacing={1}
        justify="center"
        alignItems="center"
      >
        <Grid item xs>
          <Atoms.Text variant="caption" display="block" size="11" noMargin fontFamily="book" bold textCenter >
            B2B CCYs:
          </Atoms.Text>
        </Grid>
        <Grid item>
            <Atoms.Button fullWidth noBorder size="small" disabled ></Atoms.Button>
        </Grid>
        <Grid item xs>
          <Atoms.Text variant="caption" display="block" size="11" noMargin fontFamily="book" bold textCenter >
            WAREHOUSING:
          </Atoms.Text>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid
        container
        spacing={1}
        justify="center"
        alignItems="center"
      >
        <Grid item xs> {customList(left)} </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Atoms.Button
              fullWidth
              color={props.stopStatus ? "error" : "success"}
              size="large"
              onClick={props.handleStop}
            >
              {props.stopStatus ? "STOP" : "GO"}
            </Atoms.Button>
            <Space size="10"/>
            <Atoms.Button
              fullWidth
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <ChevronRight fontSize="small" />
            </Atoms.Button>
            <Space size="10"/>
            <Atoms.Button
              fullWidth
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              <ChevronLeft fontSize="small" />
            </Atoms.Button>
            <Space size="10"/>
            <Atoms.Button
              fullWidth
              color={props.b2bStatus ? "warning" : ""}
              onClick={props.handleB2B}
            >
              B2B
            </Atoms.Button>
          </Grid>
        </Grid>
        <Grid item xs>{customList(right)}</Grid>
      </Grid>
    </Grid>
    </React.Fragment>
  );
}

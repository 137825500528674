import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ToggleButtonStyle} from "../../Styles/Atoms/Button";

export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <ToggleButtonStyle
      {...props}
      value={props.value}
      aria-label={props.label}
      theme={com.theme}
    >
        {props.children}
    </ToggleButtonStyle>
  );
};

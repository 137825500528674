import React, { useContext, useState } from "react";
import { ContextBase } from "../../../Hooks";
import {Wrapper} from "../../../Styles/Atoms/TableAtoms/BtnGroup"
import Button from "../../Button"
import ButtonIcon from "../../ButtonIcon"
import Pause from '@material-ui/icons/Pause';
import Play from '@material-ui/icons/PlayArrow';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

export default (props:any) => {
  let com: any = useContext(ContextBase);
  const rowDataBtn = com.streamData;
  
  const rowDataOut = com.outboundData;
  const thisRowIndex = props.rowIndex ? props.rowIndex : 0;
  let initialBidTierValue = null;
  let initialOfferTierValue = null
  if (rowDataOut[thisRowIndex]){
    initialBidTierValue = rowDataOut[thisRowIndex].bidTier
    initialOfferTierValue = rowDataOut[thisRowIndex].offerTier
  }
  const [bidTier, setBidTier] = useState(initialBidTierValue);
  const [offerTier, setOfferTier] = useState(initialOfferTierValue);

  const actionSaveRowStream = (rowIndex, newStatus) => {
    const newData = [...rowDataBtn]
    newData.forEach(e=>{
       if(e.Stream==rowIndex) 
        e.Status=newStatus
    })
    com.dispatch({type:"saveStreamData", payload:newData})
    return null;
  };

  const actionSaveTierChange = (bid, offer) => {
    const newData = [...rowDataOut]

    newData.splice(thisRowIndex, 1,{
      Pair: newData[thisRowIndex].Pair, 
      time: newData[thisRowIndex].time,
      Stream: newData[thisRowIndex].Stream, 
      tier: newData[thisRowIndex].tier,
      Bid: newData[thisRowIndex].Bid,
      bidTier: bidTier+bid,
      Spread: newData[thisRowIndex].Spread, 
      Offer: newData[thisRowIndex].Offer,
      offerTier: offerTier+offer,
      Tenor: newData[thisRowIndex].Tenor
      })

    com.dispatch({type:"saveOutboundData", payload:newData})
      
    return null;
  };

  const handleUpButton = ()=> {
    
    actionSaveTierChange(-1,+1);
  }

  const handleLeftButton = ()=> {
    
    actionSaveTierChange(-1,-1);
  }

  const handleMiddleButton = ()=> {
    
    actionSaveTierChange(-bidTier,-offerTier);
  }

  const handleRightButton = ()=> {

    actionSaveTierChange(+1, +1);
  }

  const handleDownButton = ()=> {
    
    actionSaveTierChange(+1,-1);
  }

  return (
    <Wrapper {...props} theme={com.theme}>
      <div className="contentA">
        {props.stream ? (<ButtonIcon className="btnTable" color={(props.data.Status == "LIVE") ? "error" : "success"} onClick={()=>actionSaveRowStream(props.data.Stream, props.data.Status==="LIVE" ? "STOPPED" : "LIVE")}>
            {(props.data.Status === "LIVE") ? <Pause fontSize="small"/> : <Play />}
          </ButtonIcon> )
        : (<React.Fragment>
          <Button reduceIcon onClick={handleUpButton}>
            <svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg" className="icon top">
              <path className="icon-color" d="M9.63698 0.251972L16.9355 7.21875C17.2875 7.55475 17.2875 8.09949 16.9355 8.43545L16.0842 9.24801C15.7328 9.58343 15.1633 9.58408 14.8111 9.24944L8.99964 3.72815L3.18825 9.24944C2.83602 9.58408 2.26651 9.58343 1.91511 9.24801L1.06386 8.43545C0.711859 8.09945 0.711859 7.55471 1.06386 7.21874L8.3623 0.251971C8.7143 -0.0839927 9.28498 -0.0839927 9.63698 0.251972Z" />
            </svg>
          </Button>
            <div>
              <Button reduceIcon onClick={handleLeftButton}>
                <svg width="10" height="18" viewBox="0 0 10 18" xmlns="http://www.w3.org/2000/svg" >
                  <path className="icon-color" d="M0.251972 8.3629L7.21875 1.06441C7.55475 0.712415 8.09949 0.712415 8.43545 1.06441L9.24801 1.91567C9.58343 2.26706 9.58408 2.83658 9.24944 3.18881L3.72815 9.00024L9.24944 14.8116C9.58408 15.1639 9.58343 15.7334 9.24801 16.0848L8.43545 16.936C8.09945 17.288 7.55471 17.288 7.21874 16.936L0.251972 9.63757C-0.0839924 9.28558 -0.0839924 8.7149 0.251972 8.3629Z" fill="white"/>
                </svg>
              </Button> 
              <Button reduceIcon onClick={handleMiddleButton}><FiberManualRecord className="warning"/></Button>
              <Button reduceIcon onClick={handleRightButton}>
                <svg width="10" height="18" viewBox="0 0 10 18" xmlns="http://www.w3.org/2000/svg" className="icon">
                  <path className="icon-color" d="M9.74803 9.63735L2.78125 16.9358C2.44525 17.2878 1.90051 17.2878 1.56455 16.9358L0.751992 16.0846C0.416565 15.7332 0.41592 15.1637 0.750557 14.8114L6.27185 9.00001L0.750557 3.18862C0.415919 2.83639 0.416565 2.26687 0.751991 1.91548L1.56455 1.06422C1.90055 0.712225 2.44529 0.712225 2.78125 1.06422L9.74803 8.36267C10.084 8.71467 10.084 9.28535 9.74803 9.63735Z" fill="white"/>
                </svg>
              </Button>
            </div>
          <Button reduceIcon className="bottomX" onClick={handleDownButton}>
            <svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg" className="icon bottom">
              <path className="icon-color" d="M8.36253 9.74803L1.06405 2.78125C0.712049 2.44525 0.712049 1.90051 1.06405 1.56455L1.9153 0.751992C2.2667 0.416565 2.83621 0.41592 3.18844 0.750558L8.99987 6.27185L14.8113 0.750558C15.1635 0.41592 15.733 0.416565 16.0844 0.751992L16.9357 1.56455C17.2877 1.90055 17.2877 2.44529 16.9357 2.78125L9.63721 9.74803C9.28521 10.084 8.71453 10.084 8.36253 9.74803Z" fill="white"/>
            </svg>
          </Button>
        </React.Fragment>)
        }
      </div>
    </Wrapper>
  );
};

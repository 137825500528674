import { Colors } from "../../Constants";
import Styled from "styled-components";

const Wrapper = Styled.div<any>`
  width: 100%;
  height: 100%;
  background-color: ${props => Colors[props.theme].background.body} ;
  padding: 8px 8px 0;
  overflow: auto;
  box-sizing: border-box;
  color: ${props => Colors[props.theme].primary.light} ;
  scrollbar-color: ${props => Colors[props.theme].background.scrollFront} ${props => Colors[props.theme].background.scroll};
  scrollbar-width: thin;
  
  ::-webkit-scrollbar, * ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
    background:  ${props => Colors[props.theme].background.scrollFront};
  }

  ::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
    background: ${props => Colors[props.theme].background.scroll};
  }

  
  
  .rightMovilCenter{
    text-align: right;
  }
  /* @media screen and (max-width:959px){
    height: auto;
  } */
  @media screen and (max-width:1174px){
    .rightMovilCenter{
      text-align: center;
    }
  }
  @media screen and (max-width:959px){
    .rightMovilCenter{
      text-align: right;
    }
  }
  @media screen and (max-width:599px){
    .rightMovilCenter{
      text-align: center;
    }
  }
`;

export default Wrapper;

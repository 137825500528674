import styled from 'styled-components';
import { Colors } from "../../../Constants";

const Wrapper = styled.div`
height: 100%;
.contentA{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: red; */
}
  div {
    line-height: 0 !important;
    height: 24px;
  }
  .warning {
    color: ${props => Colors[props.theme].warning.light};
  }
  .icon-color {
    fill: ${props => Colors[props.theme].primary.light} !important;
  }
  .icon{
    height: 22px;
  }
  .top, .bottom{
    width: 18px !important;
  }
  .top, .bottom{
    height: 10px !important;
  }
`

export {Wrapper};

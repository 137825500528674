import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ContentInput, Input} from "../../Styles/Atoms/Input";
import Text from "../Text"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return <ContentInput {...props} theme={com.theme}>
          <div>
            <Text variant="caption" size="11" fontFamily="book" bold textCenter textNoWrap noMargin>{props.label}</Text>
          </div>
          <Input {...props} theme={com.theme}>{props.children}</Input>
        </ContentInput>
      ;
};

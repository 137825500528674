import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {Text} from "../../Styles/Atoms/Text";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <Text
      {...props}
      theme={com.theme}
      gutterBottom
    >
        {props.children}
    </Text>
  );
};

import { FontSize, Colors, Fonts } from "../../Constants";
import styled from "styled-components";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

const AgGridReactStyle = styled(AgGridReact)`
  background-color: transparent !important;
  .ag-root-wrapper{
    background-color: transparent !important;
    border: 0 !important;
  }
  .ag-header{
    background-color: ${props => Colors[props.theme].background.headerTable} !important;
    margin-bottom: 8px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${props => Colors[props.theme].border.main} !important;
    height: 28px !important;
    min-height: 28px !important;
    box-sizing: border-box;
  }
  .ag-header-cell{
    padding: 0 !important;
  }
  .ag-header-cell-label{
    justify-content: center;
  }
  .ag-react-container{
    height: 100%;
  }
  .ag-header-row{
    color: ${props => Colors[props.theme].primary.invert} !important;
    ${FontSize.header};
    font-family:${Fonts.primaryBook};
    height: 25px !important;
  }
  .ag-row{
    background-color: transparent !important;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${props => Colors[props.theme].border.main} !important;
    box-sizing: border-box;
    color: ${props => Colors[props.theme].primary.light} !important;
    & .ag-cell-wrapper:first-child{
      justify-content: flex-start !important;
    }
  }
  .ag-cell{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ag-cell-focus{
    outline: none !important;
    border-color: transparent !important;
  }
  .ag-cell-wrapper{
    justify-content: center !important;
    .ag-row-drag{
      margin-right: 6px !important;
      color: ${props => Colors[props.theme].primary.light} !important;
    }
  }
`;
const AgGridColumnStyle = styled(AgGridColumn)`
  background-color: transparent !important;
  color: ${props => Colors[props.theme].secondary.light};
`;

export {AgGridReactStyle, AgGridColumnStyle};

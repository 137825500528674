import {normalize} from './Config';
export default {
  h5: {
    space: `clamp(4px, ${normalize(2)}vw, 7px) !important`,
  },
  h10: {
    space: `clamp(9px, ${normalize(2)}vw, 12px) !important`,
  },
  h15: {
    space: `clamp(12px, ${normalize(2)}vw, 17px) !important`,
  },
  h20: {
    space: `clamp(18px, ${normalize(2)}vw, 22px) !important`,
  },
};

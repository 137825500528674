import React, { useEffect, useReducer, createContext } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { reducer, initialState } from "../Hooks/Hooks.reducers";

let ContextBase: any = createContext(initialState);

const Provider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /* BEGIN WebSocket Code */
  const socketUrl = "ws://localhost:4001"; // use/run /webSocketServer for testing
    const {
      sendMessage,
      lastMessage,
      readyState,
    } = useWebSocket(socketUrl);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
   
  useEffect(() => {
    console.log('websocket status: ', connectionStatus)
    dispatch({type: "saveConnectionStatus", payload: connectionStatus})
    dispatch({type: "saveStateUpdateFunction", payload: dispatch})
  }, [connectionStatus]);
/* END WebSocket Code */

  return (
    <ContextBase.Provider value={state}>
      {state.response && `provider ${state.response}`}
      {props.children}
    </ContextBase.Provider>
  );
};
export default Provider;
export { ContextBase };

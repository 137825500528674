 import React from "react";
 import { useContext } from "react";
 import { ContextBase } from "../../../Hooks";
import {Wrapper} from "../../../Styles/Atoms/TableAtoms"
import Text from "../../Text"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <Wrapper {...props} theme={com.theme} onlyOne={props.onlyStream}>
      <div className="contentA">
        <Text variant="caption" display="block" size={props.onlyStream ? "14":"9"} noMargin fontFamily="book" textCenter color="primaryMain">{props.value}</Text>
      </div>
      { !props.onlyStream && <div className="contentB">
        <Text variant="caption" display="block" size="9" noMargin fontFamily="book" textCenter color="primaryMain">TIER: <Text variant="caption" size="9" noMargin fontFamily="bold" color="primaryLight">{props.data.tier}</Text></Text>
      </div>}
    </Wrapper>
  );
};

import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Space} from "../../Styles/Atoms/Space"
import Atoms from "../../Atoms"
 

export default function ForwardCurve() {

  const [bidTD, setBidTD] = useState("")
  const [bidTOM, setBidTOM] = useState("")
  const [bidSPOT, setBidSPOT] = useState("")
  const [bidSN, setBidSN] = useState("")
  const [bid2D, setBid2D] = useState("")
  const [bid1W, setBid1W] = useState("")
  const [bid2W, setBid2W] = useState("")
  const [bid3W, setBid3W] = useState("")
  const [bid1M, setBid1M] = useState("")
  const [bid3M, setBid3M] = useState("")
  const [bid6M, setBid6M] = useState("")
  const [bid9M, setBid9M] = useState("")
  const [bid1Y, setBid1Y] = useState("")
  const [bid2Y, setBid2Y] = useState("")
  const [bid3Y, setBid3Y] = useState("")
  const [bidNextFuture, setBidNextFuture] = useState("")
  const [bid2Future, setBid2Future] = useState("")

  const [offerTD, setOfferTD] = useState("")
  const [offerTOM, setOfferTOM] = useState("")
  const [offerSPOT, setOfferSPOT] = useState("")
  const [offerSN, setOfferSN] = useState("")
  const [offer2D, setOffer2D] = useState("")
  const [offer1W, setOffer1W] = useState("")
  const [offer2W, setOffer2W] = useState("")
  const [offer3W, setOffer3W] = useState("")
  const [offer1M, setOffer1M] = useState("")
  const [offer3M, setOffer3M] = useState("")
  const [offer6M, setOffer6M] = useState("")
  const [offer9M, setOffer9M] = useState("")
  const [offer1Y, setOffer1Y] = useState("")
  const [offer2Y, setOffer2Y] = useState("")
  const [offer3Y, setOffer3Y] = useState("")
  const [offerNextFuture, setOfferNextFuture] = useState("")
  const [offer2Future, setOffer2Future] = useState("")
  const [saving, setSaving] = useState(false)
  const [disableSave, setDisableSave] = useState(false);

  const saveChanges = () => {
    setSaving(true)
    setTimeout(() => {
      setDisableSave(true)
      setSaving(false)
    }, 4000);
    
  }

  const validateBidInput=(type:string, num:any)=>{
    const validInput = /^$|^\d{0,6}$|^-\d{0,6}$|^\d{1,6}\.\d{0,6}$|^-\d{1,6}\.\d{0,6}$/g
    // const validInput = /^$|^[0-9]+$/g;
    if (num.match(validInput) && num.length<15) {
      switch (type) {
        case "bidTD":
          setBidTD(num);
          break;
        case "bidTOM":
          setBidTOM(num);
          break;
        case "bidSPOT":
          setBidSPOT(num);
          break;
        case "bidSN":
          setBidSN(num);
          break;
        case "bid2D":
          setBid2D(num);
          break;
        case "bid1W":
          setBid1W(num);
          break;
        case "bid2W":
          setBid2W(num);
          break;
        case "bid3W":
          setBid3W(num);
          break;
        case "bid1M":
          setBid1M(num);
          break;
        case "bid3M":
          setBid3M(num);
          break;
        case "bid6M":
          setBid6M(num);
          break;
        case "bid9M":
          setBid9M(num);
          break;
        case "bid1Y":
          setBid1Y(num);
          break;
        case "bid2Y":
          setBid2Y(num);
          break;
        case "bid3Y":
          setBid3Y(num);
          break;
        case "bidNextFuture":
          setBidNextFuture(num);
          break;
        case "bid2Future":
          setBid2Future(num);
          break;
      
        default:
          break;
      }
    } else { return }
  }

  const validateOfferInput=(type:string, num:any)=>{
    const validInput = /^$|^\d{0,6}$|^-\d{0,6}$|^\d{1,6}\.\d{0,6}$|^-\d{1,6}\.\d{0,6}$/g
    // const validInput = /^$|^[0-9]+$/g;
    if (num.match(validInput) && num.length<15) {
      switch (type) {
        case "offerTD":
          setOfferTD(num);
          break;
        case "offerTOM":
          setOfferTOM(num);
          break;
        case "offerSPOT":
          setOfferSPOT(num);
          break;
        case "offerSN":
          setOfferSN(num);
          break;
        case "offer2D":
          setOffer2D(num);
          break;
        case "offer1W":
          setOffer1W(num);
          break;
        case "offer2W":
          setOffer2W(num);
          break;
        case "offer3W":
          setOffer3W(num);
          break;
        case "offer1M":
          setOffer1M(num);
          break;
        case "offer3M":
          setOffer3M(num);
          break;
        case "offer6M":
          setOffer6M(num);
          break;
        case "offer9M":
          setOffer9M(num);
          break;
        case "offer1Y":
          setOffer1Y(num);
          break;
        case "offer2Y":
          setOffer2Y(num);
          break;
        case "offer3Y":
          setOffer3Y(num);
          break;
        case "offerNextFuture":
          setOfferNextFuture(num);
          break;
        case "offer2Future":
          setOffer2Future(num);
          break;
      
        default:
          break;
      }
    } else { return }
  }

  return (
    <React.Fragment>
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item> 
          <Grid container direction="column" alignItems="flex-start">
            <Atoms.Select name="select" label="CCY.PAIR:" column>
              <Atoms.Option value="value1">EUR/USD</Atoms.Option >
              <Atoms.Option  value="value2" defaultValue="value2">EUR/PLZ</Atoms.Option >
              <Atoms.Option  value="value3">EUR/MXN</Atoms.Option > 
            </Atoms.Select>
            <Space size="10"/>
            <Atoms.Checkbox label="TD" value="td"/>
            <Space size="10"/>
            <Atoms.Checkbox label="TOM" value="tom"/>
            <Space size="10"/>
            <Atoms.Checkbox label="SPOT" value="spot"/>
            <Space size="10"/>
            <Atoms.Checkbox label="SN" value="sn"/>
            <Space size="10"/>
            <Atoms.Checkbox label="2D" value="2d"/>
            <Space size="10"/>
            <Atoms.Checkbox label="1W" value="1w"/>
            <Space size="10"/>
            <Atoms.Checkbox label="2W" value="2w"/>
            <Space size="10"/>
            <Atoms.Checkbox label="3W" value="3w"/>
            <Space size="10"/>
            <Atoms.Checkbox label="1M" value="3m"/>
            <Space size="10"/>
            <Atoms.Checkbox label="3M" value="3m"/>
            <Space size="10"/>
            <Atoms.Checkbox label="6M" value="6m"/>
            <Space size="10"/>
            <Atoms.Checkbox label="9M" value="9m"/>
            <Space size="10"/>
            <Atoms.Checkbox label="1Y" value="1y"/>
            <Space size="10"/>
            <Atoms.Checkbox label="2Y" value="2y"/>
            <Space size="10"/>
            <Atoms.Checkbox label="3Y" value="3y"/>
            <Space size="10"/>
            <Atoms.Checkbox label="Next Future" value="next future"/>
            <Space size="10"/>
            <Atoms.Checkbox label="2 Future" value="2 future"/>
            <Space size="10"/>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container direction="column" alignItems="center">
            <Atoms.Text variant="caption" display="block" size="14" noMargin fontFamily="book" textCenter>BID</Atoms.Text>
            <Space size="10"/>
            <Atoms.Input type="text" name="bidTD" value={bidTD} onChange={(e)=>{validateBidInput("bidTD", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bidTOM" value={bidTOM} onChange={(e)=>{validateBidInput("bidTOM", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bidSPOT" value={bidSPOT} onChange={(e)=>{validateBidInput("bidSPOT", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bidSN" value={bidSN} onChange={(e)=>{validateBidInput("bidSN", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid2D" value={bid2D} onChange={(e)=>{validateBidInput("bid2D", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid1W" value={bid1W} onChange={(e)=>{validateBidInput("bid1W", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid2W" value={bid2W} onChange={(e)=>{validateBidInput("bid2W", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid3W" value={bid3W} onChange={(e)=>{validateBidInput("bid3W", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid1M" value={bid1M} onChange={(e)=>{validateBidInput("bid1M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid3M" value={bid3M} onChange={(e)=>{validateBidInput("bid3M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid6M" value={bid6M} onChange={(e)=>{validateBidInput("bid6M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid9M" value={bid9M} onChange={(e)=>{validateBidInput("bid9M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid1Y" value={bid1Y} onChange={(e)=>{validateBidInput("bid1Y", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid2Y" value={bid2Y} onChange={(e)=>{validateBidInput("bid2Y", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid3Y" value={bid3Y} onChange={(e)=>{validateBidInput("bid3Y", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bidNextFuture" value={bidNextFuture} onChange={(e)=>{validateBidInput("bidNextFuture", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="bid2Future" value={bid2Future} onChange={(e)=>{validateBidInput("bid2Future", e.target.value)}}/>
            <Space size="10"/>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container direction="column" alignItems="center">
            <Atoms.Text variant="caption" display="block" size="14" noMargin fontFamily="book" textCenter>OFFER</Atoms.Text>
            <Space size="10"/>
            <Atoms.Input type="text" name="offerTD" value={offerTD} onChange={(e)=>{validateOfferInput("offerTD", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offerTOM" value={offerTOM} onChange={(e)=>{validateOfferInput("offerTOM", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offerSPOT" value={offerSPOT} onChange={(e)=>{validateOfferInput("offerSPOT", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offerSN" value={offerSN} onChange={(e)=>{validateOfferInput("offerSN", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer2D" value={offer2D} onChange={(e)=>{validateOfferInput("offer2D", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer1W" value={offer1W} onChange={(e)=>{validateOfferInput("offer1W", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer2W" value={offer2W} onChange={(e)=>{validateOfferInput("offer2W", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer3W" value={offer3W} onChange={(e)=>{validateOfferInput("offer3W", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer1M" value={offer1M} onChange={(e)=>{validateOfferInput("offer1M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer3M" value={offer3M} onChange={(e)=>{validateOfferInput("offer3M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer6M" value={offer6M} onChange={(e)=>{validateOfferInput("offer6M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer9M" value={offer9M} onChange={(e)=>{validateOfferInput("offer9M", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer1Y" value={offer1Y} onChange={(e)=>{validateOfferInput("offer1Y", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer2Y" value={offer2Y} onChange={(e)=>{validateOfferInput("offer2Y", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer3Y" value={offer3Y} onChange={(e)=>{validateOfferInput("offer3Y", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offerNextFuture" value={offerNextFuture} onChange={(e)=>{validateOfferInput("offerNextFuture", e.target.value)}}/>
            <Space size="10"/>
            <Atoms.Input type="text" name="offer2Future" value={offer2Future} onChange={(e)=>{validateOfferInput("offer2Future", e.target.value)}}/>
            <Space size="10"/>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <Atoms.Button fullWidth color="secondary" onClick={saveChanges} disabled={disableSave}>
            {saving ? "SAVING" : disableSave ? "DONE!" : "SAVE"}
            {saving && <div className="LoaderBalls">
              <div className="LoaderBalls__item ball1"></div>
              <div className="LoaderBalls__item ball2"></div>
              <div className="LoaderBalls__item ball3"></div>
            </div>
            }
          </Atoms.Button>
        </Grid>
      </Grid>
    </Grid>
    </React.Fragment>
  );
}

import { Colors, FontSize, Fonts } from "../../Constants";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Styled from "styled-components";

const CheckLabel = Styled(FormControlLabel)<any>`
  height: 30px !important;
  color: ${props => Colors[props.theme].primary.light};
  font-family:${Fonts.primaryBook};
  span[class*="label"]{
    ${FontSize.sz14};
  }
`;
const Check = Styled(Checkbox)<any>`
  color: ${props => Colors[props.theme].primary.light } !important;
  &[class*="checked"]{
    color: ${props => Colors[props.theme].secondary.checkbox } !important;
  }
`;


export {CheckLabel, Check};
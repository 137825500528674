import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {AgGridReactStyle} from "../../Styles/Atoms/AgGrid"

import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../Styles/Components/TableAg/ag-theme.css";

import Pair from "../../Atoms/TableAtoms/Pair";
import Stream from "../../Atoms/TableAtoms/Stream";
import Price from "../../Atoms/TableAtoms/Price"
import Spread from "../../Atoms/TableAtoms/Spread"
const Table = (props: any) => {
  let com: any = useContext(ContextBase);
  const [rowData, setRowData] = useState([]);

  const columnDefs = [
    // {
    //   field: '',
    //   minWidth: 25,
    //   maxWidth: 25,
    //   rowDrag: true,
    //   lockPosition: true,
    // },
    {
      field: 'Pair',
      cellRenderer: "pairCellRenderer",
      minWidth: 85,
      lockPosition: true,
      rowDrag: true,
      cellRendererParams: {
        inbound: true
      },
    },
    {
      field: "Stream",
      cellRenderer: "streamCellRenderer",
      minWidth: 70,
      maxWidth: 70,
      lockPosition: true,
    },
    {
      field: "Bid",
      cellRenderer: "bidCellRenderer",
      cellRendererParams: {
        inbound: true
      },
      minWidth: 98,
      lockPosition: true,
    },
    {
      field: "Spread",
      style: "background: red",
      cellRenderer: "spreadCellRenderer",
      cellRendererParams: {
        inbound: true
      },
      minWidth: 45,
      maxWidth: 45,
      lockPosition: true,
    },
    {
      field: "Offer",
      cellRenderer: "offerCellRenderer",
      cellRendererParams: {
        inbound: true
      },
      minWidth: 98,
      lockPosition: true,
    }
  ]

  const defaultColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    // minWidth: 100,
    filter: false,
    resizable: true,
    // autoHeight: true,
    rowHeight: 150,
  };

  const frameworkComponents={
      pairCellRenderer: Pair,
      streamCellRenderer: Stream,
      bidCellRenderer: Price,
      spreadCellRenderer: Spread,
      offerCellRenderer: Price, 
  }

  const onColumnResized = (params:any) => {
    params.api.resetRowHeights();
  };

  const onColumnVisible = (params:any) => {
    params.api.resetRowHeights();
  };

  useEffect(()=>{
    setRowData(props.rowData);
  },[props.rowData])

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }} >
      {/* <ComponetDummy /> */}
      <AgGridReactStyle
        key={"inbound"+com.theme}
        theme={com.theme}
        rowData={rowData}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        defaultColDef={defaultColDef}
        rowDragManaged={true}
        animateRows={true}
        rowHeight={60}
        onColumnResized={onColumnResized}
        onColumnVisible={onColumnVisible}
      >
        {/* <AgGridColumnStyle field="make"></AgGridColumnStyle>
        <AgGridColumnStyle field="model"></AgGridColumnStyle>
        <AgGridColumnStyle field="price"></AgGridColumnStyle> */}
      </AgGridReactStyle>
    </div>
  );
};

export default Table;

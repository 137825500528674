import { Colors, FontSize, Fonts } from "../../Constants";

import Styled from "styled-components";

const ContentSelect = Styled.div<any>`
  display: flex;
  flex-direction: ${props => props.column ? "column" : "row"};
  align-items: ${props => props.column ? "flex-start" : "center"};
  color: ${props => Colors[props.theme].primary.light};
  ${FontSize.sz14};
  /* margin: 0 -4px; */
`;
const Select = Styled.select<any>`
  border: 1px solid;
  cursor: pointer;
  border-color: #7a7a7a !important;
  background-color: transparent;
  color: ${props => Colors[props.theme].primary.main};
  height: 30px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  ${FontSize.sz14};
  text-transform: uppercase;
  font-family: ${Fonts.primaryBold};
  &:focus{
    outline: none !important;
  }
`;

const Option = Styled.option<any>`
  background-color: ${props => Colors[props.theme].gray.main};
  color: ${props => Colors[props.theme].primary.main};
  text-transform: uppercase;
`;

export {ContentSelect, Select, Option};
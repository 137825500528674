import styled, {css} from 'styled-components';
import {SpaceSize} from '../../Constants';

const Space = styled.span<any>`
  ${props => props.size === '10' && css`
    margin-left: ${SpaceSize.h10.space};
    margin-bottom: ${SpaceSize.h10.space};
  `};
  ${props => props.size === '15' && css`
    margin-left: ${SpaceSize.h15.space};
    margin-bottom: ${SpaceSize.h15.space};
  `};
  ${props => props.size === '20' && css`
    margin-left: ${SpaceSize.h20.space};
    margin-bottom: ${SpaceSize.h20.space};
  `};
  
  ${props => props.size === 'w10' && css`
    margin-left: ${SpaceSize.h10.space};
  `};
  ${props => props.size === 'w15' && css`
    margin-left: ${SpaceSize.h15.space};
  `};
  ${props => props.size === 'w20' && css`
    margin-left: ${SpaceSize.h20.space};
  `};
  
  ${props => props.size === 'h10' && css`
    margin-bottom: ${SpaceSize.h10.space};
  `};
  ${props => props.size === 'h15' && css`
    margin-bottom: ${SpaceSize.h15.space};
  `};
  ${props => props.size === 'h20' && css`
    margin-bottom: ${SpaceSize.h20.space};
  `};
  ${props => props.block && css`
    width: 100%;
  `}
  
  ${props => props.movilFullWidth && css`
    @media screen and (max-width:599px){
      display: block !important;
    }
  `}
`;


export {Space};
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Grid from '@material-ui/core/Grid';
import Atoms from "../../Atoms";
import Components from "../../Components"
import {AppBarStyle, TabsStyle, TabStyle} from "../../Styles/Atoms/Tabs";
import PropTypes from 'prop-types';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{height: "100%"}}
    > 
      {value === index && (
          <div style={{height: "100%"}}>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InputPanel = (props:any) => {
  let com: any = useContext(ContextBase);
  const pairSelectOptions = com.pairSelectOptions;
  const streamSelectOptions = com.streamSelectOptions;
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [tempPair, setTempPair] = useState(pairSelectOptions[0]);
  const [tempStream, setTempStream] = useState(streamSelectOptions[0]);
  const [tempTier, setTempTier] = useState("");
  const [rowData, setRowData] = useState([])
  const [isEmpty, setIsEmpty] = React.useState(false)
  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };
  
  const validateTier=(num)=>{
    const validInput = /^$|^[0-9]+$/g;
    if (num.match(validInput) && num.length<4) {
      setTempTier(num)
      setIsEmpty(false);
    } else { return }
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTempPair(pairSelectOptions[0]);
    setTempStream(streamSelectOptions[0]);
    setTempTier("");
    setIsEmpty(false);
    setOpen(false);
  };

  const addZeroToTime = (min) =>{
    const tempMin = Number(min)
    if (tempMin<10) {
      return "0"+tempMin
    } else {
      return min;
    }
  }
  
  const actionSaveRowInbound = () => {
    const newData = [...rowData]
    const hours = addZeroToTime(new Date().getHours());
    const minutes = addZeroToTime(new Date().getMinutes());

    if (tempTier!==""){
      newData.push({
        Pair: tempPair, 
        time: hours+":"+minutes,
        Stream: tempStream, 
        tier: tempTier+"M",
        Bid: "49.0020",
        spread: 20, 
        Offer: 4.4920,
        })
      com.dispatch({type:"saveInboundData", payload:newData})
      setTempPair(pairSelectOptions[0]);
      setTempStream(streamSelectOptions[0]);
      setTempTier("");
      setIsEmpty(false);
      handleClose();
    } else {
      setIsEmpty(true);
    }
    return null;
  };

  useEffect(()=>{
    setRowData(com.inboundData)
  },[com.inboundData])

  return (
    
    <Grid item xs={12} md={4}>
    <Atoms.Card fullHeight whitHeader>

      <Atoms.HeaderCard justify="space-between">

        <AppBarStyle position="static" theme={com.theme}>
          <TabsStyle value={value} onChange={handleChange} aria-label="simple tabs example" theme={com.theme}>
            <TabStyle label="Inbound rates" {...a11yProps(0)} theme={com.theme} />
            <TabStyle label="Forward curve" {...a11yProps(1)} theme={com.theme} />
          </TabsStyle>
        </AppBarStyle>

        {value===0 && <Atoms.Button textColorHeader size="small" className="hiddenText" noBorder startIcon={<AddCircleOutline/>} onClick={handleClickOpen}><span className="hiddenText">Add</span></Atoms.Button>}
        
        <Atoms.Dialog
          open={open}
          onClose={handleClose}
          onSave={actionSaveRowInbound}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          titleText="Add Inbound"
          newItem
        >
          
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Atoms.Select 
                name="pairSelect" 
                label="CCY.PAIR:" 
                column 
                onChange={(e)=>setTempPair(e.target.value)} 
                // defaultValue={"EUR/PLZ"}
              >
                {pairSelectOptions.map((item, index)=>{
                  return <Atoms.Option key={"pairSelectOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Atoms.Select 
                name="streamSelect" 
                label="STREAM:" 
                column
                onChange={(e)=>setTempStream(e.target.value)} 
              >
                {streamSelectOptions.map((item, index)=>{
                  return <Atoms.Option key={"streamSelectOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Atoms.Input 
                type="text" 
                name="tier" 
                label="TIER:" 
                error={isEmpty}
                column 
                value={tempTier}
                onChange={(e)=>validateTier(e.target.value)}
              />
            </Grid>
          </Grid>
        </Atoms.Dialog>

      </Atoms.HeaderCard>
      <Atoms.CardBody tableContent>
        <TabPanel value={value} index={0}>
          <Components.TableInbound rowData={rowData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Components.ForwardCurve/>
        </TabPanel>
      </Atoms.CardBody>

    </Atoms.Card>
  </Grid>
  );
};

export default InputPanel;

import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {AgGridReactStyle} from "../../Styles/Atoms/AgGrid"

import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../Styles/Components/TableAg/ag-theme.css";

import Stream from "../../Atoms/TableAtoms/Stream";
import Status from "../../Atoms/TableAtoms/Status"
import BtnGroup from "../../Atoms/TableAtoms/BtnGroup";

const Table = (props:any) => {
  let com: any = useContext(ContextBase);
  /* const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null); */
  const columnDefs = [
    /* {
      field: '',
      minWidth: 25,
      maxWidth: 25,
      rowDrag: true,
      lockPosition: true,
    }, */
    {
      field: 'Stream',
      cellRenderer: "streamCellRenderer",
      rowDrag: true,
      cellRendererParams: {
        onlyStream: true,

      },
      minWidth: 100,
      lockPosition: true,
    },
    {
      field: "Status",
      cellRenderer: "statusCellRenderer",
      minWidth: 90,
      lockPosition: true,
    },
    {
      headerName: '',
      field: "btn",
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        stream: true,
      },
      minWidth: 80,
      lockPosition: true,
    },
  ];
  const [defaultColDef, setDefaultColDef] = useState(
    {
      editable: false,
      sortable: false,
      flex: 1,
      // minWidth: 100,
      filter: false,
      resizable: true,
      // autoHeight: true,
      rowHeight: 150,
    },
  );

  const [frameworkComponents, setFrameworkComponents] = useState({
      btnCellRenderer: BtnGroup,
      streamCellRenderer: Stream,
      statusCellRenderer: Status,
    
  });
  const [rowData, setRowData] = useState([]);

  const onColumnResized = (params:any) => {
    params.api.resetRowHeights();
  };

  const onColumnVisible = (params:any) => {
    params.api.resetRowHeights();
  };

  useEffect(()=>{
    setRowData(props.rowData);
  },[props.rowData])

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      {/* <ComponetDummy /> */}
      <AgGridReactStyle
        key={"streams"+com.theme}
        theme={com.theme}
        rowData={rowData}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        defaultColDef={defaultColDef}
        rowDragManaged={true}
        animateRows={true}
        rowHeight={60}
        onColumnResized={onColumnResized}
        onColumnVisible={onColumnVisible}
      >
        {/* <AgGridColumnStyle field="make"></AgGridColumnStyle>
        <AgGridColumnStyle field="model"></AgGridColumnStyle>
        <AgGridColumnStyle field="price"></AgGridColumnStyle> */}
      </AgGridReactStyle>
    </div>
  );
};

export default Table;

import Styled from "styled-components";
import { Colors, FontSize, Fonts } from "../../Constants";
import Paper from "@material-ui/core/Paper";

const BoxList = Styled(Paper)<any>`
  border-radius: 4px !important;
  background-color: ${props => Colors[props.theme].background.light} !important;
  color: ${props => Colors[props.theme].primary.light} !important;
  width: 100%;
  height: 160px;
  padding: 0 !important;
  box-shadow: none !important;
  overflow: auto;
  .active {
    background-color: ${props => Colors[props.theme].gray.light} !important;
  }
  span{ 
    ${FontSize.sz14};
    font-family: ${Fonts.primaryBook}
  }
`;

export {BoxList};
import { FontSize, Colors } from "../../Constants";
import Styled from "styled-components";

const Text = Styled.h4`
@media screen and (max-width:1020px){
 ${FontSize.sz10}

}
@media screen and (max-width:820px){
  ${FontSize.sz9}
}

color: ${props => Colors[props.theme].textColor} ;
background:${props => Colors[props.theme].backgroundColor} ; 
`;

const TextC = (props: any) => {
  return <Text theme={props.theme}> .text</Text>;
};

export default TextC;

import Atom1 from "./Atom1";
import Button from "./Button";
import ButtonIcon from "./ButtonIcon";
import Card from "./Card";
import CardBody from "./CardBody";
import Checkbox from "./Checkbox";
import Dialog from "./Dialog";
import HeaderCard from "./HeaderCard";
import ToogleButton from "./ToogleButton";
import Select from "./Select";
import Option from "./Option";
import Input from "./Input";
import Text from "./Text";
export default { 
    Atom1,
    Button,
    ButtonIcon,
    Card,
    CardBody,
    Checkbox,
    Dialog,
    HeaderCard,
    ToogleButton,
    Select,
    Option,
    Input,
    Text
};

import React, {useState,useEffect } from "react";
import { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Atoms from "../../Atoms";
import {Space} from "../../Styles/Atoms/Space"
import TransferList from "../TransferList";
import Parameters from "../Parameters";
import { ContextBase } from "../../Hooks";
import Grid from '@material-ui/core/Grid';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Hidden from '@material-ui/core/Hidden';
import { ToggleButtonGorupStyle } from '../../Styles/Atoms/Button'

const useStyles = makeStyles({
  root: {
    height: "calc(100% - 36px)",
    '@media (max-width: 959px)': {
      height: "auto",
    }
  },
});

const SelectTheme = (props: any) => {
  const classes = useStyles();
  let com: any = useContext(ContextBase);
  const streamSelectOptions = com.streamSelectOptions;
  const warehousingAlgoOptions = com.warehousingAlgoOptions;
  const streamData = com.streamData;

  const [stopStatus, setStopStatus] = useState(true);
  const [stopAll, setStopAll] = useState(true);
  const [b2bStatus, setB2bStatus] = useState(false) 
  const [b2bAll, setB2BAll] = useState(false);
  const [tempStream, setTempStream] = useState(streamSelectOptions[0]);
  const [tempWarehousingAlgo, setTempWarehousingAlgo] = useState(warehousingAlgoOptions[0]);
  
  useEffect(() => {
    const currentStreamStatus = streamData&&streamData.find(e=>e.Stream== tempStream)
    setStopStatus(currentStreamStatus?(currentStreamStatus.Status=="LIVE"?true:false):true);
  });

  const changeStreamStatus = () => {
    const newData = [...streamData]
    const newStatus = stopAll ? "STOPPED" : "LIVE";
    newData.forEach(element => {
      element.Status = newStatus;
    });

    com.dispatch({type:"saveStreamData", payload:newData})
    return null;
  };

  const changeStreamSingleStatus = () => {
    const newData = [...streamData]
    const newStatus = stopStatus ? "STOPPED" : "LIVE";
    newData.forEach(element => {
      if (element.Stream === tempStream){
        element.Status = newStatus;
      }
    });
    com.dispatch({type:"saveStreamData", payload:newData})
    return null;
  };

  const handleStop = () => {
    changeStreamSingleStatus();
    setStopStatus(!stopStatus);
    
  };

  const handleB2B = () => {
    setB2bStatus(!b2bStatus);
  };

  const handleStopAll = () => {
    changeStreamStatus();
    setStopAll(!stopAll);
    setStopStatus(!stopAll);
  };

  const handleB2BAll = () => {
    setB2BAll(!b2bAll);
    setB2bStatus(!b2bAll);
  };
  
  const handleStreamOption=(value)=>{
    const currentStreamStatus = streamData&&streamData.find(e=>e.Stream==value)
    setTempStream(value)
    setStopStatus(currentStreamStatus?(currentStreamStatus.Status=="LIVE"?true:false):true);
  }

  const [alignment, setAlignment] = React.useState('normal');
  const handleChangeTogle = (event:any, newAlignment:any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  
  return (
    <Grid item xs={12} md={4} className={classes.root}>
      <Atoms.Card fullHeight whitHeader>
        <Atoms.HeaderCard justify="space-between">
          <Grid container spacing={1} justify="center" >
            
            <Hidden xsDown>
              <Grid item >
                <Atoms.Button textColorHeader movilFullWidth color={stopAll ? "error" : "success"} onClick={handleStopAll} >{stopAll ? "STOP ALL" : "GO"}</Atoms.Button> 
              </Grid>
              <Grid item  >
                <Atoms.Button textColorHeader movilFullWidth color={b2bAll ? "warning" : ""} onClick={handleB2BAll}>B2B ALL</Atoms.Button> 
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={12} sm  >
                <Atoms.Button textColorHeader movilFullWidth color={stopAll ? "error" : "success"} onClick={handleStopAll} >{stopAll ? "STOP ALL" : "GO"}</Atoms.Button> 
              </Grid>
              <Grid item xs={12} sm>
                <Atoms.Button textColorHeader movilFullWidth color={b2bAll ? "warning" : ""} onClick={handleB2BAll}>B2B ALL</Atoms.Button> 
              </Grid>
            </Hidden>
            <Grid item xs={12} sm className="rightMovilCenter">
              <ToggleButtonGorupStyle theme={com.theme} size="small" value={alignment} exclusive onChange={handleChangeTogle}>
                <Atoms.ToogleButton textColorHeader value="normal" label="normal">
                  NORMAL
                </Atoms.ToogleButton>
                <Atoms.ToogleButton textColorHeader value="50" label="50">
                  50%
                </Atoms.ToogleButton>
                <Atoms.ToogleButton textColorHeader value="200" label="200">
                  200%
                </Atoms.ToogleButton>
                <Atoms.ToogleButton textColorHeader value="500" label="500">
                  500%
                </Atoms.ToogleButton>
              </ToggleButtonGorupStyle>
            </Grid>
          </Grid>
        </Atoms.HeaderCard>
        <Atoms.CardBody >
          <Grid container spacing={1} justify="flex-start" >
            <Grid item xs={12} sm={8} md={8}>
              <Atoms.Select 
                name="select" 
                label="Stream Set Up:"
                onChange={(e)=>handleStreamOption(e.target.value)} 
              >
                {streamSelectOptions.map((item, index)=>{
                  return <Atoms.Option key={"streamSelectOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
            <TransferList 
              selectedStream={tempStream} 
              b2bStatus={b2bStatus}
              b2bAllStatus={b2bAll}
              stopStatus={stopStatus}
              handleStop={handleStop}
              handleB2B={handleB2B}
            />
            <Grid item xs={12} >
              <Space size="15" />
            </Grid>
            <Grid item xs={12} sm={8} md={12} lg={10}>
              <Atoms.Select 
                name="select" 
                label="Warehousing Algorithm:"
                onChange={(e)=>setTempWarehousingAlgo(e.target.value)}
              >
                {warehousingAlgoOptions.map((item, index)=>{
                  return <Atoms.Option key={"warehousingAlgoOption-"+index} value={item}>{item}</Atoms.Option >
                })}
              </Atoms.Select>
            </Grid>
            <Parameters selectedAlgo={tempWarehousingAlgo}/>
          </Grid>
        </Atoms.CardBody>
      </Atoms.Card>
    </Grid>
  );
};

export default SelectTheme;

import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {Btn} from "../../Styles/Atoms/Button";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <Btn
      {...props}
      size={props.size ? props.size : "small"}
      movilFullWidth={props.movilFullWidth}
      fullWidth={props.fullWidth}
      theme={com.theme}
      variant={props.variant}
      // startIcon={props.startIcon && <Icon>{props.startIcon}</Icon>}
      // endIcon={props.endIcon && <Icon>{props.endIcon}</Icon>}
    >
        {props.children}
    </Btn>
  );
};
